import { DateTime } from 'luxon'

/**
 * Returns a money string in the format '£123.45'
 * @param value - number
 * @returns string
 */
export const formatMoney = (value: any) => {
  try {
    const values = Number.isInteger(parseInt(value, 10))
      ? (value / 100).toFixed(2).split('.')
      : ['0', '00']
    return {
      pounds: values[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      pence: values[1],
    }
  } catch (e) {
    console.log(e)
    return {
      pounds: '0',
      pence: '00',
    }
  }
}

/**
 * Returns a date string in short format, e.g. '22 Jun 24'
 * @param date - ISO date string
 * @returns string
 */
export const dateToShortString = (date: string) => {
  return DateTime.fromISO(date, { zone: 'utc' }).toFormat('dd LLL yy')
}

/**
 * Returns a time string in 24-hour format, e.g. '22:00'
 * @param date - ISO date string
 * @returns string
 */
export const formatTime = (date: string) => {
  return DateTime.fromISO(date, { zone: 'utc' }).toFormat('HH:mm')
}

/**
 * Returns a date string in human-readable format, e.g. '22/06/2024'
 * @param date - ISO date string
 * @returns string
 */
export const formatToHumanDate = (date: string) => {
  return DateTime.fromISO(date, { zone: 'utc' }).toLocaleString(
    DateTime.DATE_SHORT
  )
}

/**
 * Returns a string of the integer part of a number in the format '£123.45'
 * @param value - number
 * @returns string
 */
export const formatPrice = (value: number) => {
  const val = (value / 100).toFixed(2)
  const str = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return str.slice(0, -2)
}

/**
 * Returns a string of the decimal part of a number, e.g.
 * with a value of 3.75, 75 is returned
 * @param value - number
 * @returns string
 */
export const formatDecimal = (value: number) => {
  const val = (value / 100).toFixed(2)
  const str = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return str.slice(-2)
}

/**
 * Returns a string of fees in the format '10p + 10%'
 * @param fees - array of { type: string, amount: string }
 * @returns string
 */
export const formatFees = (fees: { type: string; amount: string }[]) => {
  if (fees.length === 0) {
    return 'N/A'
  }
  return fees
    .map((fee) => {
      if (fee.type === 'fixed') {
        return fee.amount + 'p'
      } else {
        return fee.amount + '%'
      }
    })
    .join(' + ')
}
