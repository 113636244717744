import store from '@/store'
import debug from '@/helpers/debug'
import { SET_CHARITY_ID } from '@/store/mutation-types'

export const debugRouterGuard = (to: any, from: any) => {
  debug('[ROUTER GUARDS] debugRouterGuards', Date.now())
  return true
}

export const requiresNoAuthentication = (to: any, from: any) => {
  debug('[ROUTER GUARDS] requiresNoAuthentication', { to, from, store })

  if (import.meta.env.MODE === 'mockapi') {
    debug('[ROUTER GUARDS] Mock API is enabled')
    return true
  }

  // @ts-ignore
  if (store.state.auth.authenticated) {
    return { name: 'Dashboard' }
  }

  return true
}

export const requiresAuthentication = (to: any, from: any) => {
  debug('[ROUTER GUARDS] requiresAuthentication', { to, from, store })

  if (import.meta.env.MODE === 'mockapi') {
    debug('[ROUTER GUARDS] Mock API is enabled')
    return true
  }

  // @ts-ignore
  if (store.state.auth.authenticated) {
    return true
  }

  return { name: 'Login' }
}

export const redirectOnAuthCallback = async (to: any, from: any) => {
  debug('[ROUTER GUARDS] redirectOnAuthCallback', { to, from })

  if (to.hash.includes('access_token')) {
    debug('access token found in hash')
    await store.dispatch('storeToken', to.hash)
    return { name: 'Dashboard' }
  }

  if (to.hash.includes('error_description')) {
    debug('An authentication error occurred', to.hash)
    return { name: 'Login' }
  }

  return true
}

export const checkMaintenanceMode = (to: any, from: any) => {
  debug('[ROUTER GUARDS] checkForMaintenanceMode', { to, from, store })

  const isUnderMaintenance =
    // @ts-ignore
    import.meta.env.VITE_MAINTENANCE || store.state.app.maintenance

  if (to.name !== 'Maintenance' && isUnderMaintenance) {
    return { name: 'Maintenance' }
  }

  if (to.name === 'Maintenance' && !isUnderMaintenance) {
    return { name: 'Dashboard' }
  }

  return true
}

export const fetchCharitySummary = async (to: any, from: any) => {
  debug('[ROUTER GUARDS] fetchCharitySummary', { to, from, store })

  await store.dispatch('fetchCharitySummary', to.params.id)

  return true
}

export const setCharityId = async (to: any, from: any) => {
  debug('[ROUTER GUARDS] setCharityId', { to, from, store })

  store.commit(SET_CHARITY_ID, to.params.id)

  return true
}

export const checkAuth0Session = async (to: any, from: any) => {
  debug('[ROUTER GUARDS] checkAuth0Session', { to, from, store })

  if (import.meta.env.MODE === 'mockapi') {
    debug('[ROUTER GUARDS] Mock API is enabled')
    await store.dispatch('fetchCurrentUser')
    return true
  }

  let authResult
  try {
    authResult = await store.dispatch('auth0CheckSession')

    if (!authResult) {
      throw new Error('No result from Auth0')
    }

    await store.dispatch('storeToken', authResult)
    const userResponse = await store.dispatch('fetchCurrentUser')
    if (userResponse.data.landingPage === 0) {
      return { name: 'Details' }
    }

    return true
  } catch (error) {
    debug('Error in App#checkAuth0Session', { cause: error })
    return { name: 'Login' }
  }
}

export const handleSignUpState = (to: any, from: any) => {
  debug('[ROUTER GUARDS] handleSignUpState', { to, from, store })

  if (import.meta.env.MODE === 'mockapi') {
    debug('[ROUTER GUARDS] Mock API is enabled')
    return true
  }

  return { name: 'Dashboard' }
}
