import { nextTick } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'
import store from '@/store'
import { CLOSE_MENU, CLOSE_MOBILE_MENU } from '@/store/mutation-types'
import AuthLayout from '@/layouts/AuthLayout.vue'
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import AppLayout from '@/layouts/AppLayout.vue'
import Maintenance from '@/layouts/Maintenance.vue'
import {
  requiresAuthentication,
  requiresNoAuthentication,
  debugRouterGuard,
  checkMaintenanceMode,
  fetchCharitySummary,
  checkAuth0Session,
  handleSignUpState,
  redirectOnAuthCallback,
  setCharityId,
} from '@/router/guards'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Maintenance',
      component: Maintenance,
      beforeEnter: [checkMaintenanceMode],
    },
    {
      path: '/login',
      component: AuthLayout,
      children: [
        {
          name: 'Login',
          path: '',
          component: () => import('../views/login.vue'),
          meta: {
            title: 'Login',
          },
          beforeEnter: [requiresNoAuthentication],
        },
        {
          name: 'Details',
          path: '/details',
          component: () => import('../views/details.vue'),
          meta: {
            title: 'Details',
          },
          beforeEnter: [requiresAuthentication],
        },
        {
          name: 'HMRCAuthorisationRouterView',
          path: '/hmrc-authorisation',
          component: () =>
            import('../views/hmrc-authorisation/router-view.vue'),
          beforeEnter: [requiresAuthentication, checkAuth0Session],
          children: [
            {
              name: 'HMRCAuthorisation',
              path: '',
              component: () => import('../views/hmrc-authorisation/form.vue'),
              meta: {
                title: 'HMRC nominee assignment',
              },
            },
            {
              name: 'HMRCAuthorisationConflict',
              path: 'conflict',
              component: () =>
                import('../views/hmrc-authorisation/conflict.vue'),
              meta: {
                title: 'HMRC nominee assignment conflict',
              },
            },
            {
              name: 'HMRCAuthorisationComplete',
              path: 'complete/:id',
              component: () =>
                import('../views/hmrc-authorisation/complete.vue'),
              meta: {
                title: 'HMRC nominee assignment registered',
              },
            },
          ],
        },
      ],
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
      component: DashboardLayout,
      meta: {
        title: 'Dashboard',
      },
      beforeEnter: [requiresAuthentication, checkAuth0Session],
      children: [
        {
          name: 'Dashboard',
          path: '',
          component: () => import('../views/dashboard/index.vue'),
        },
      ],
    },
    {
      path: '/charity/:id',
      component: AppLayout,
      beforeEnter: [
        requiresAuthentication,
        checkAuth0Session,
        fetchCharitySummary,
        setCharityId,
      ],
      children: [
        {
          path: 'gift-aid',
          component: () => import('../views/charity/gift-aid/router-view.vue'),
          meta: {
            isDashboard: true,
          },
          children: [
            {
              name: 'GiftAid',
              path: '',
              component: () => import('../views/charity/gift-aid/index.vue'),
              meta: {
                isDashboard: true,
                title: 'Gift Aid | Charity',
              },
            },
            {
              name: 'Claim',
              path: 'claim/:claimId',
              component: () => import('../views/charity/gift-aid/claim.vue'),
              meta: {
                isDashboard: true,
                title: 'Claim | Charity',
              },
            },
          ],
        },
        {
          name: 'DonationSources',
          path: 'donation-sources',
          component: () => import('../views/charity/donation-sources.vue'),
          meta: {
            isDashboard: true,
            title: 'Donation sources | Charity',
          },
        },
        {
          name: 'FindGiftAid',
          path: 'find-gift-aid',
          component: () => import('../views/charity/find-gift-aid.vue'),
          meta: {
            isDashboard: true,
            title: 'Gift Aid Finder | Charity',
          },
        },
      ],
    },
    {
      name: 'Callback',
      path: '/callback',
      component: AuthLayout,
      meta: {
        title: 'Signing in',
      },
      beforeEnter: [
        debugRouterGuard,
        requiresNoAuthentication,
        redirectOnAuthCallback,
        handleSignUpState,
      ],
    },
    {
      path: '/:catchAll(.*)*',
      redirect: { name: 'Dashboard' },
    },
  ],
})

router.beforeEach(async (to) => {
  // Close the menu on any route change
  store.commit(CLOSE_MENU)

  // Dispatch necessary actions and bypass rest of guard
  // if using mock API/testing.
  if (import.meta.env.MODE === 'mockapi') {
    store.dispatch('storeMockToken')
  }

  return true
})

const DEFAULT_TITLE = 'Swiftaid for charities'
router.afterEach((to) => {
  nextTick(() => {
    if (to.path.includes('charity')) {
      setTimeout(() => {
        store.commit(CLOSE_MOBILE_MENU)
      }, 300)
    }

    let title = DEFAULT_TITLE

    if (to.meta.title) {
      title = `${to.meta.title} | ${DEFAULT_TITLE}`
    }

    document.title = title
    document
      .querySelector('meta[property="og:title"]')
      ?.setAttribute('content', title)
    document
      .querySelector('meta[name="twitter:title"]')
      ?.setAttribute('content', title)

    if (to.meta.description) {
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute('content', String(to.meta.description))
      document
        .querySelector('meta[property="og:description"]')
        ?.setAttribute('content', String(to.meta.description))
      document
        .querySelector('meta[name="twitter:description"]')
        ?.setAttribute('content', String(to.meta.description))
    }
  })
})

export default router
