<script setup lang="ts">
import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import type * as Bootstrap from 'bootstrap'
import {
  CLOSE_FEED_MENU,
  CLOSE_MENU,
  TOGGLE_FEED_MENU,
  TOGGLE_MENU,
  TOGGLE_MOBILE_MENU,
} from '@/store/mutation-types'
import ProfilePlaceholder from '@/components/generic/NavBar/ProfilePlaceholder.vue'
import ProfileMenu from '@/components/generic/NavBar/ProfileMenu.vue'
import NotificationPlaceholder from '@/components/generic/DashNavBar/NotificationPlaceholder.vue'
import NotificationFeed from '@/components/generic/DashNavBar/NotificationFeed.vue'
import InfoBox from '@/components/generic/InfoBox.vue'

const store = useStore()
const router = useRouter()

const bootstrap = inject('bootstrap') as typeof Bootstrap
const editProfileModal = new bootstrap.Modal('#editProfileModal')

const sidebarIsOpen = computed(() => store.getters.sidebarIsOpen)
const user = computed(() => store.getters.user)
const charityName = computed(() => store.getters.charityName)
const sources = computed(() => store.getters.sources)
const notifications = computed(() => store.getters.notifications)
const menu = computed(() => store.getters.menu)
const feedMenu = computed(() => store.getters.feedMenu)
const notificationsCount = computed(() => {
  // Count for the localised no donation source message
  const noDonationSourceNotificationCount = sources.value.length === 0 ? 1 : 0
  return notifications.value.length + noDonationSourceNotificationCount
})

const editProfile = () => {
  store.commit(TOGGLE_MENU)
  editProfileModal.toggle()
}

const logout = async () => {
  try {
    await store.dispatch('auth0Logout')
  } catch (error) {
    console.error(error)
  } finally {
    store.commit(CLOSE_MENU)
    router.push({ name: 'Login' })
  }
}

const toggleProfileMenu = () => {
  store.commit(TOGGLE_MENU)
  store.commit(CLOSE_FEED_MENU)
}

const toggleNotificationFeed = () => {
  store.commit(CLOSE_MENU)
  store.commit(TOGGLE_FEED_MENU)
}

const toggleMobileMenu = () => {
  store.commit(TOGGLE_MOBILE_MENU)
}
</script>

<template>
  <nav class="navbar">
    <div class="container-fluid mx-3">
      <div class="d-none d-lg-flex justify-content-center align-items-center">
        <router-link :to="{ name: 'Dashboard' }" class="navbar-brand">
          <img
            src="@/assets/images/logo-sa.svg"
            width="28px"
            height="36px"
            :alt="$t('Generic.DashNavBar.NavLogoImageAlt')"
          />
        </router-link>
        <div class="navbar-divider"></div>
        <transition name="title-fade" appear>
          <h1
            v-if="charityName"
            class="h6 fw-semibold text-center mb-0"
            data-testid="charity-name"
          >
            {{ charityName }}
          </h1>
        </transition>
      </div>

      <div class="navbar-bars d-lg-none">
        <div class="menu-wrapper" @click.prevent="toggleMobileMenu">
          <div :class="{ animate: sidebarIsOpen }" class="hamburger-menu"></div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex gap-3 position-relative">
          <NotificationPlaceholder
            v-if="notifications"
            id="navbarNotification"
            :notifications-count="notificationsCount"
            class="mr-3 position-relative"
            data-testid="navbarNotification"
            @toggleFeed="toggleNotificationFeed"
          />
          <transition name="menu-fade">
            <NotificationFeed
              v-if="feedMenu"
              id="notificationFeed"
              @close="toggleNotificationFeed"
            />
          </transition>

          <ProfilePlaceholder id="navbarProfile" @click="toggleProfileMenu" />
          <transition name="menu-fade">
            <InfoBox
              v-if="menu"
              id="profileMenu"
              class="dash-nav-bar"
              @close="toggleProfileMenu"
            >
              <template #content>
                <ProfileMenu
                  :firstname="user.firstname"
                  :lastname="user.lastname"
                  @edit="editProfile"
                  @logout="logout"
                />
              </template>
            </InfoBox>
          </transition>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.navbar-divider {
  border-left: solid 2px var(--mid-grey);
  height: 34px;
  margin-right: 15px;
  margin-top: -3px;
  float: left;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: var(--black);
  font-size: 20px;
}

.modal-title {
  text-align: left;
  &.userName {
    margin: 25px 0 25px 80px;
  }
}

.navbar {
  .nav-item {
    padding: 0;
    height: 100%;
  }

  .navbar-logout {
    display: initial;
    .modal-title {
      font-size: 20px;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
}

$bar-width: 30px;
$bar-height: 4px;
$bar-spacing: 9px;

.menu-wrapper {
  margin: auto;
  width: $bar-width;
  height: $bar-height + $bar-spacing * 2;
  cursor: pointer;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
  height: $bar-height;
}

.hamburger-menu {
  position: relative;
  transform: translateY($bar-spacing);
  background: rgba(var(--black-rgb), 1);
  transition: all 0ms 300ms;
  &.animate {
    background: rgba(var(--black-rgb), 0);
  }
}

.hamburger-menu:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: rgba(var(--black-rgb), 1);
  transition:
    bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
  content: '';
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: rgba(var(--black-rgb), 1);
  transition:
    top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition:
    top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition:
    bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
</style>
